import React from "react";
import Swiper from "../components/carousel/Swiper";
import Aboutus from "../components/company/About-us";
const OurbusinessPage = () => {
  return (
    <>
      <div>
        {/* <Swiper /> */}
        <Aboutus />
      </div>
    </>
  );
};

export default OurbusinessPage;
