export const ourExpertData = [
  {
    name: " Lorem ipsum.",
    bio: "lorem are you ok bro",
    img: "https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg",
    profileLink: "",
    linkedinLink: "",
  },
  {
    name: " Lorem ipsum ",

    bio: "lorem are you ok bro",
    img: "https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg",
    profileLink: "",
    linkedinLink: "",
  },
  {
    name: " Lorem ipsum ",

    bio: "lorem are you ok bro",
    img: "https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg",
    profileLink: "",
    linkedinLink: "",
  },
  {
    name: " Lorem ipsum ",

    bio: "lorem are you ok bro",
    img: "https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg",
    profileLink: "",
    linkedinLink: "",
  },
  {
    name: " Lorem ipsum ",
    bio: "lorem are you ok bro",
    img: "https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg",
    profileLink: "",
    linkedinLink: "",
  },
  {
    name: " Lorem ipsum.",
    bio: "lorem are you ok bro",
    img: "https://cdn.tailgrids.com/2.0/image/application/images/blogs/blog-01/image-01.jpg",
    profileLink: "",
    linkedinLink: "",
  },
];
